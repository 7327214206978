import React from 'react';
import {css} from '@emotion/react';
import pluralize from 'pluralize';
import {formatTime} from 'lib/helpers';
import Section from './Section';
import type {Entities} from 'types';

type CourseProps = Required<Required<Entities.Event>['race']>;

type Props = {
  lastPlayed?: CourseProps['course_last_played'];
  topRecords: CourseProps['course_top_records'];
  topPlayers: CourseProps['course_top_players'];
  showPercentage?: boolean;
};

export default function CourseStats({lastPlayed, topRecords, topPlayers, showPercentage = true}: Props) {
  return (
    <div css={styles.root}>
      <Section
        flex={0.7}
        title="Top Times"
        records={topRecords.map(({player_name, race_time}) => ({player_name, value: formatTime(race_time)}))}
      />
      <Section
        title="Top Players"
        titleColor="blue"
        records={topPlayers.map(({player_name, wins, games, ratio}) => ({
          player_name,
          value: `${wins} ${pluralize('win', wins)} / ${games} ${pluralize('game', games)} ${
            showPercentage ? `(${Math.round(ratio * 100)}%)` : ''
          }`,
        }))}
      />
      {lastPlayed != null && lastPlayed.length > 0 && (
        <Section
          flex={0.85}
          title="Last Played"
          titleColor="green"
          records={lastPlayed.map(({player_name, last_played}) => ({
            player_name,
            value: last_played ?? '✨ Never ✨',
          }))}
        />
      )}
    </div>
  );
}

const styles = {
  root: css`
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  `,
};
