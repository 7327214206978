import React from 'react';
import {useParams} from 'react-router-dom';
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import request from 'lib/request';
import FullScreenLoader from '../../components/FullScreenLoader';
import PageHeader from 'components/PageHeader';
import Heading from 'components/Heading';
import type {Entities} from 'types';
import PlayerTopTimesBarChart from './components/PlayerTopTimesBarChart';
import TopPlayersPieChart from './components/TopPlayersPieChart';
import CourseImage from 'components/CourseImage';
import {formatTime} from 'lib/helpers';
import Section from 'components/CourseStats/Section';
import pluralize from 'pluralize';

export default function Course() {
  const {name} = useParams();
  const {data: course, isLoading} = useQuery<Entities.ComplexCourse>(['courses', name], () => {
    return request(`/api/kartalytics/courses/${name}`);
  });

  if (isLoading || course == null) return <FullScreenLoader />;

  return (
    <div css={styles.root}>
      <PageHeader />
      <div css={styles.header}>
        <CourseImage imageUrl={course.image} />
        <Heading level="3">{course.name}</Heading>
      </div>
      <div css={styles.charts}>
        <div css={styles.chart}>
          <Heading css={styles.title} color="yellow">
            Fastest Times
          </Heading>
          <PlayerTopTimesBarChart players={course.uniq_top_records} />
        </div>
        <div css={styles.chart}>
          <Heading css={styles.title} color="blue">
            Win %
          </Heading>
          <TopPlayersPieChart players={course.top_players} />
        </div>
      </div>
      <div css={styles.courseStats}>
        <Section
          flex={1}
          title="Top Times"
          records={course.uniq_top_records.map(({player_name, race_time}) => ({
            player_name,
            value: formatTime(race_time),
          }))}
        />
        <Section
          flex={1}
          title="Top Players"
          titleColor="blue"
          records={course.top_players.map(({player_name, wins, games, ratio}) => ({
            player_name,
            value: `${wins} ${pluralize('win', wins)} / ${games} ${pluralize('game', games)}`,
          }))}
        />
      </div>
    </div>
  );
}

const styles = {
  root: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 8rem;
    padding-top: 4rem;
  `,

  chart: css`
    flex: 1;
  `,

  charts: css`
    display: flex;
    flex-direction: row;
    height: 50rem;
    max-width: 150rem;
    width: 100%;
    gap: 5rem;
    margin-top: 10rem;
  `,

  courseStats: css`
    margin-top: 15rem;
    max-width: 150rem;
    width: 100%;
    display: flex;
  `,

  header: css`
    max-width: 150rem;
    position: relative;
    width: 50rem;
    margin: 0 auto;
  `,

  name: css`
    padding-top: 0.5em;
    padding-left: 0.33em;
  `,

  player: css`
    margin-right: 4em;
    text-align: center;
  `,

  title: css`
    font-family: SuperMario;
    font-size: 4.8rem;
    margin-bottom: 2rem;
  `,
};
